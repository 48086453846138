import React, { useEffect } from "react";
import stock from "../../../images/stock12.png";
import { Helmet } from "react-helmet";

const NISMSeriesICurrency = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  });
  return (
    <>
      <Helmet>
        <title>
          NISM Certification - Commodity Market Module | Trading Smart Edge
        </title>
        <meta
          name="description"
          content="Prepare for NISM Certification in Commodity Market with TSE Institute. Master commodity trading operations and enhance your career in the financial sector."
        />
        <meta
          name="keywords"
          content="NISM Certification, Commodity Market, Trading Smart Edge, Financial Market Courses, NSE, TSE Institute, Commodity Trading, Career in Finance"
        />
        <meta name="author" content="Hemant Sharma" />
      </Helmet>
      <div className="container">
        <div className="container mt-5 mb-5">
          <p className="allheading">
            Commodity Market Module - NISM CERTIFICATION
          </p>
          <div className="row">
            <div className="col-md-6">
              <img width={"100%"} src={stock} alt="trading smart edge images" />
            </div>
            <div className="col-md-6">
              <p className="para" style={{ color: "gray" }}>
                NCFM Commodity Market (NSE) Dealer Module Certification & Exam
                Preparation by TSE INSTITUTE Leading Stock Market Institute For
                Financial Market Courses in Delhi NCR. Commodity market dealer
                module, a one-month program for aspirants wish to master
                Commodity market operations. The program offers an overview into
                the different types of commodities. Learn 5 aspects of commodity
                trading on MCX and NCDEX. TSE institute is a best place for top
                commodity trading course. The program offers powerful insight
                into various tools and techniques need to understand Commodity
                market size, structure, and functions. Accelerate your career as
                a Commodity market dealer to get a job in National Stock
                Exchange (NSE).
              </p>
              <p className="para" style={{ color: "gray" }}>
                NCFM Commodity market module is one of the most important
                programs of the National Stock Exchange (NSE). It is a must-do
                module to get a job in Stock Broking Company. Aspirants planning
                to start their own stock broking business can also enrol for
                this short-term course. Besides, who wants to add value to
                resume when applying for jobs in the financial sector/ IT/ BPO.
              </p>
            </div>
            <div className="col-md-6">
              <p className="para">
                <b>Unique features of Diploma in Investment Advisory</b>
              </p>
              <p className="para" style={{ color: "gray" }}>
                The NSE / NCFM Commodity Market course prepares students to
                attain a position in the Commodity market as an analyst or
                strategist. Commodity market is one of the hottest professions.
                The demand for Commodity market analyst will rise to 22% by
                2020. TSE Institute Commodity Market Module gives a foundational
                knowledge of Commodity market instruments, fundamental
                valuation, major exchange, legal framework, and more.
              </p>
              <p className="para">
                <b>What Student Will Get?</b>
              </p>
              <p className="para">
                Upon completion of the Commodity Market module, students will
                receive a certificate from TSE and from NCFM. The certificate
                will testify your skills as an expert in the Commodity Market.
                You will also receive the following:
              </p>
              <ol>
                <li>NSE Commodity Market Certification</li>
                <li>NCFM Commodity Market Certification</li>
                <li>
                  TSE CERTIFICATION – Certification of Commodity Market Course
                </li>
              </ol>
              <p className="para" style={{ color: "gray" }}>
                # NCFM Certificates are subjected to appearance for the exam at
                the NSE Centre. You will get 100% preparation at the branch.
              </p>
            </div>
            <div className="col-md-6">
              <img width={"100%"} src={stock} alt="trading smart edge images" />
            </div>
          </div>

          <p className="para">DETAILED CURRICULUM</p>
          <p className="para">Commodity Market (NCFM Certification)</p>

          <div class="accordion accordion-flush" id="accordionFlushExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingOne">
                <p
                  class="accordion-button collapsed accordionheading"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne"
                >
                  Commodity Market (NCFM Certification)
                </p>
              </h2>
              <div
                id="flush-collapseOne"
                class="accordion-collapse collapse"
                aria-labelledby="flush-headingOne"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="accordion-body">
                  <ol>
                    <li>Understanding Commodity Market</li>
                    <li>Live Trading in Commodities – MCX and NCDEX</li>
                    <li>International commodity Vs Domestic Commodity</li>
                  </ol>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingTwo">
                <p
                  class="accordion-button collapsed accordionheading"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseTwo"
                  aria-expanded="false"
                  aria-controls="flush-collapseTwo"
                >
                  Who Can Take Commodity Market Module?
                </p>
              </h2>
              <div
                id="flush-collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="flush-headingTwo"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="accordion-body">
                  <p className="para">
                    Aspirants who complete the Commodity market course will
                    develop a skill that helps you land your dream job in the
                    primary and secondary market. Jobs that are ideal for
                    Commodity market professionals include:
                  </p>
                  <ol>
                    <li>
                      Commodity market beginners can take the course to build a
                      lucrative career in financial services.
                    </li>
                    <li>
                      Teachers, investors, and anybody interest to work in
                      Indian stock market operations can join the Commodity
                      market module to enhance learning.
                    </li>
                    <li>
                      Stock brokers, sub-brokers, and stock analyst those
                      looking to open new career opportunities can take the
                      course.
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NISMSeriesICurrency;
