import React, { useEffect } from "react";
import stock2 from "../../../images/ai2.jpg";
import investor from "../../../images/stock2.png";
import { Helmet } from "react-helmet";
const ProfessionalInvestorCourse = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  });
  return (
    <>
      <Helmet>
        <title>Professional Investor Course - Trading Smart Edge</title>
        <meta
          name="description"
          content="Join our Professional Investor Course to master stock market strategies and improve your investment skills."
        />
        <meta
          name="keywords"
          content="Professional Investor Course, stock market, investment strategies, Nifty, Sensex, NSE, BSE, financial education"
        />
      </Helmet>
      <div className="container mt-5 mb-5">
        <p className="allheading">Professional Investor Course</p>
        <div className="row">
          <div className="col-md-6">
            <img src={stock2} width={"100%"} alt="trading smart edge images" />
          </div>
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-12">
                <p className="para">
                  As a beginner in the stock market, staying in sync with Top
                  Indexes Nifty & Sensex and being in a consistent profit, or
                  seeking a stockbroker who gives discounted brokerage; can be
                  quite an onerous task. The Professional Trader Course has been
                  contrived to bring about the best of you through a curriculum
                  that is based largely upon learning through live
                  trading models, as a result, making you an adept stock trader.
                  This course further helps you to get a better understanding of
                  how the financial market operates. The meticulously
                  formulated course is a comprehensive trading program, which
                  encompasses all the aspects of the
                  major share exchanges like NSE & BSE. This live
                  trading program trains you to use websites like Money
                  control & Investing.com and efficiently strategize your
                  trades.
                </p>
                <p className="para">
                  Professional Trader - Ultimate Trading program is, by far, the
                  most advanced, all-encompassing and a thorough trading course
                  in the Financial market.  Basics of the Stock Market
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-md-7">
            <div class="accordion accordion-flush" id="accordionFlushExample">
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingOne">
                  <p
                    class="accordion-button collapsed accordionheading"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                    Basic Module
                  </p>
                </h2>
                <div
                  id="flush-collapseOne"
                  class="accordion-collapse collapse"
                  aria-labelledby="flush-headingOne"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    <ol>
                      <li className="para">
                        Markets and Financial Instruments
                      </li>
                      <li className="para">Primary Market</li>
                      <li className="para">Secondary Market</li>
                      <li className="para">Index Investment</li>
                    </ol>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingTwo">
                  <p
                    class="accordion-button collapsed accordionheading"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseTwo"
                    aria-expanded="false"
                    aria-controls="flush-collapseTwo"
                  >
                    Derivatives Module
                  </p>
                </h2>
                <div
                  id="flush-collapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="flush-headingTwo"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    <ol>
                      <li className="para">Basics of derivatives</li>
                      <li className="para">Understanding Index</li>
                      <li className="para">
                        Introduction to Forwards and Futures
                      </li>
                      <li className="para">Introduction to Options</li>
                      <li className="para">
                        Option Trading Strategies
                        <ul>
                          <li className="para">Scalping Strategies</li>
                          <li className="para">Hedge Strategies</li>
                          <li className="para">Arbitrage Strategies</li>
                        </ul>
                      </li>
                      <li className="para">Introduction to Trading Systems</li>
                      <li className="para">Option Greek</li>
                      <li className="para">Understanding Option Interest</li>
                      <li className="para">PCR Ratio</li>
                    </ol>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingThree">
                  <p
                    class="accordion-button collapsed accordionheading"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseThree"
                    aria-expanded="false"
                    aria-controls="flush-collapseThree"
                  >
                    Technical Analysis
                  </p>
                </h2>
                <div
                  id="flush-collapseThree"
                  class="accordion-collapse collapse"
                  aria-labelledby="flush-headingThree"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    <ol>
                      <li className="para">Time-Period Analysis</li>
                      <li className="para">Types of Charts</li>
                      <li className="para">Trend lines- Uptrend & Downtrend</li>
                      <li className="para">Breakout Theory</li>
                      <li className="para">
                        Support & Resistance
                        <ol>
                          <li className="para">
                            Intraday Support & Resistance
                          </li>
                          <li className="para">
                            Positional Trading Support & Resistance
                          </li>
                        </ol>
                      </li>
                      <li className="para">Channels</li>
                      <li className="para">EMA- Double</li>
                      <li className="para">Pivot Points</li>
                      <li className="para">Trading Divergences- Hidden</li>
                      <li className="para">Price Gap</li>
                      <li className="para">Candle Setup Theory</li>
                      <li className="para">Candlestick Pattern</li>
                      <li className="para">Trade Volume</li>
                      <li className="para">
                        Open Interest and Decision Making
                      </li>
                      <li className="para">Fibonacci</li>
                      <li className="para">Live Trading</li>
                    </ol>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingFour">
                  <p
                    class="accordion-button collapsed accordionheading"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseFour"
                    aria-expanded="false"
                    aria-controls="flush-collapseFour"
                  >
                    Advance Technical Analysis
                  </p>
                </h2>
                <div
                  id="flush-collapseFour"
                  class="accordion-collapse collapse"
                  aria-labelledby="flush-headingFour"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    <ol>
                      <li className="para">Time the Market</li>
                      <li className="para">Insider Data</li>
                      <li className="para">Risk Management</li>
                      <li className="para">Money Management</li>
                      <li className="para">Explosion Trade</li>
                    </ol>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingFive">
                  <p
                    class="accordion-button collapsed accordionheading"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseFive"
                    aria-expanded="false"
                    aria-controls="flush-collapseFive"
                  >
                    Psychology and Risk Management
                  </p>
                </h2>
                <div
                  id="flush-collapseFive"
                  class="accordion-collapse collapse"
                  aria-labelledby="flush-headingFive"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    <ol>
                      <li className="para">
                        Identification (Source and Problem Analysis)
                      </li>
                      <li className="para">Planning</li>
                      <li className="para">Constraints</li>
                      <li className="para">Framework</li>
                      <li className="para">Developing an Analysis</li>
                      <li className="para">Mitigation</li>
                      <li className="para">Other Risk Treatment</li>
                    </ol>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingSix">
                  <p
                    class="accordion-button collapsed accordionheading"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseSix"
                    aria-expanded="false"
                    aria-controls="flush-collapseSix"
                  >
                    Fundamental Analysis
                  </p>
                </h2>
                <div
                  id="flush-collapseSix"
                  class="accordion-collapse collapse"
                  aria-labelledby="flush-headingSix"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    <ol>
                      <li className="para">
                        Identification (Source and Problem Analysis)
                        <ol>
                          <li>what is Fundamental & Technical Analysis?</li>
                          <li>
                            Difference between technical & fundamental analysis
                          </li>
                          <li>Features & benefits of Fundamental analysis</li>
                        </ol>
                      </li>
                      <li>
                        Top-Down Approach in Fundamental Analysis
                        <ol>
                          <li>Economic Analysis</li>
                          <li>Industry Analysis</li>
                          <li>Company analysis</li>
                        </ol>
                      </li>
                      <li>
                        Economic Analysis
                        <p className="heading mt-5 mb-2">
                          GLOBAL & DOMESTIC RESEARCH (EVENTS)
                        </p>
                        <ol>
                          <li>Political events (Election Results)</li>
                          <li>Central Bank Meet (Central Bank events)</li>
                          <li>Government Budget</li>
                          <li>Monsoon</li>
                          <li>OPEC Meeting</li>
                          <li>War or Terrorist Attack</li>
                        </ol>
                        <p className="heading mt-5 mb-2">OTHER EVENTS</p>
                        <ol>
                          <li>Rating agencies</li>
                          <li>India VIX</li>
                          <li>Scandal</li>
                          <li>Insider Activity</li>
                          <li>Country Debt</li>
                        </ol>
                        <p className="heading mt-5 mb-2">
                          CURRENCY ANALYSIS CORRELATION OF EVENTS ECONOMIC DATA
                          ANALYSIS
                        </p>
                        <ol>
                          <li>Inventory</li>
                          <li>Retail Sales</li>
                          <li>Consumer Confidence</li>
                          <li>CPI – Consumer Price Index</li>
                          <li>PPI – Producer Price Index</li>
                          <li>Core Durable Goods Order</li>
                          <li>New Homes Sales</li>
                          <li>Building Permits</li>
                          <li>ADP Non-Farm employment</li>
                          <li>Non-Farm Employment</li>
                          <li>Unemployment Claims</li>
                          <li>PMI</li>
                          <li>Industrial Production</li>
                          <li>GDP Gross Domestic Production</li>
                          <li>Trade Balance</li>
                          <li>Crude Oil Inventory</li>
                        </ol>
                      </li>
                      <li>
                        Economic Analysis SECTOR ANALYSIS (SECTOR SELECTION)
                        <ol>
                          <li>Oil & Gas Sector</li>
                          <li>Aviation Sector</li>
                          <li>Paint Sector</li>
                          <li>T. Sector</li>
                          <li>Metal Sector</li>
                          <li>Pharma & FMCG Sector</li>
                          <li>Banking Sector</li>
                          <li>Automobile Sector</li>
                          <li>Real Estate Sector</li>
                          <li>Cement Sector</li>
                          <li>Telecom Sector</li>
                          <li>Power Sector</li>
                        </ol>
                      </li>
                      <li>Script Selection Share or Commodity Selection</li>
                      <li>
                        Company Analysis (Valuation)
                        <ol>
                          <li>Cash Flow</li>
                          <li>EPS</li>
                          <li>P/E</li>
                          <li>Book Value</li>
                          <li>Price to Book Value</li>
                          <li>EBITDA</li>
                          <li>Profit Ratios</li>
                          <li>Dividend</li>
                          <li>Market Cap</li>
                          <li>DEBT</li>
                          <li>Management</li>
                          <li>Promoters Holding</li>
                          <li>Volume</li>
                          <li>Open Interest</li>
                          <li>Beta</li>
                          <li>Moving Average</li>
                        </ol>
                        <p className="para">
                          # Inclusive With Mastering Fundamental Analysis
                          Strategies (MFA) -
                        </p>
                        <p className="para" style={{ color: "gray" }}>
                          This Strategy is based on Market & Data Analysis
                        </p>
                        <p className="para">Who Should Do This Course?</p>
                        <ol>
                          <li>MBA & BBA/CA/CS/CPT Students</li>
                          <li>
                            Anyone who wants experts in the financial market
                          </li>
                          <li>10+2, BA. B.com. B.Sc. Pursuing Students</li>
                          <li>
                            Those who want to pursue the career in financial
                            service
                          </li>
                        </ol>
                        <p>
                          <b>Career Opportunity</b>
                        </p>
                        <p className="para">
                          You can become Equity Analyst & Stock Analyst and you
                          become fit for various other posts in various other
                          verticals depending on your overall Qualifications
                        </p>
                        <p className="para">Who can take this course?</p>
                        <p className="para">
                          It is designed to help Students, Day traders,
                          Investors, Sub-brokers, stock Brokers, Chartered
                          accountants, Housewives Relationship  Managers,
                          Traders, Consultants, Entrepreneurs, etc Features &
                          Benefits
                        </p>
                        <ol>
                          <li>Instalment Facility</li>
                          <li>Daily Live trading in the classroom</li>
                          <li>3+ Mentors Cum Traders</li>
                          <li>Retakes and Class Backups Available</li>
                          <li>Lifetime Support</li>
                          <li>Free Demat A/c Opening with Upstox</li>
                        </ol>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-5">
            <img src={investor} width={"100%"} alt="Investor" />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfessionalInvestorCourse;
