import React, { useEffect } from "react";
import trading1 from "../../../images/stock1.png";
import professionalImage from "../../../images/ai1.jpg";
import { Helmet } from "react-helmet";
const ProfessionalTraderCourse = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  });
  return (
    <>
    
      <Helmet>
        <title>Professional Trader Course - Trading Smart Edge</title>
        <meta
          name="description"
          content="Join our Professional Trader Course to master stock trading and learn strategies for success in the financial markets."
        />
        <meta
          name="keywords"
          content="Professional Trader Course, stock trading, Nifty, Sensex, NSE, BSE, trading education"
        />
      </Helmet>
      <div className="container mt-5 mb-5">
        <p className="allheading">Professional Trader Course</p>
        <div className="row">
          <div className="col-md-5">
            <img
              style={{ borderRadius: "30px 0px 0px 30px" }}
              width={"100%"}
              height={"100%"}
              src={professionalImage}
            />
          </div>
          <div className="col-md-7">
            <p className="para">
              As a beginner in the stock market, staying in sync with Top
              Indexes Nifty & Sensex and being in a consistent profit, or
              seeking a stockbroker who gives discounted brokerage; can be quite
              an onerous task. The Professional Trader Course has been contrived
              to bring about the best of you through a curriculum that is based
              largely upon learning through live trading models, as a result,
              making you an adept stock trader. This course further helps you to
              get a better understanding of how the financial market operates.
              The meticulously formulated course is a
              comprehensive trading program, which encompasses all the aspects
              of the major share exchanges like NSE & BSE. This live
              trading program trains you to use websites like Money
              control & Investing.com and efficiently strategize your trades.
            </p>
          </div>
          <div className="row mt-5">
            <div className="col-md-7">
              <div class="accordion accordion-flush" id="accordionFlushExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingOne">
                    <p
                      class="accordion-button collapsed accordionheading"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOne"
                      aria-expanded="false"
                      aria-controls="flush-collapseOne"
                    >
                      Basic Module
                    </p>
                  </h2>
                  <div
                    id="flush-collapseOne"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingOne"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body">
                      <ol>
                        <li className="para">
                          Markets and Financial Instruments
                        </li>
                        <li className="para">Primary Market</li>
                        <li className="para">Secondary Market</li>
                        <li className="para">Index Investment</li>
                      </ol>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingTwo">
                    <p
                      class="accordion-button collapsed accordionheading"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseTwo"
                      aria-expanded="false"
                      aria-controls="flush-collapseTwo"
                    >
                      Derivatives Module
                    </p>
                  </h2>
                  <div
                    id="flush-collapseTwo"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingTwo"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body">
                      <ol>
                        <li className="para">Basics of derivatives</li>
                        <li className="para">Understanding Index</li>
                        <li className="para">
                          Introduction to Forwards and Futures
                        </li>
                        <li className="para">Introduction to Options</li>
                        <li className="para">
                          Option Trading Strategies
                          <ul>
                            <li className="para">Scalping Strategies</li>
                            <li className="para">Hedge Strategies</li>
                            <li className="para">Arbitrage Strategies</li>
                          </ul>
                        </li>
                        <li className="para">
                          Introduction to Trading Systems
                        </li>
                        <li className="para">Option Greek</li>
                        <li className="para">Understanding Option Interest</li>
                        <li className="para">PCR Ratio</li>
                      </ol>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingThree">
                    <p
                      class="accordion-button collapsed accordionheading"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseThree"
                      aria-expanded="false"
                      aria-controls="flush-collapseThree"
                    >
                      Technical Analysis
                    </p>
                  </h2>
                  <div
                    id="flush-collapseThree"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingThree"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body">
                      <ol>
                        <li className="para">Time-Period Analysis</li>
                        <li className="para">Types of Charts</li>
                        <li className="para">
                          Trend lines- Uptrend & Downtrend
                        </li>
                        <li className="para">Breakout Theory</li>
                        <li className="para">
                          Support & Resistance
                          <ol>
                            <li className="para">
                              Intraday Support & Resistance
                            </li>
                            <li className="para">
                              Positional Trading Support & Resistance
                            </li>
                          </ol>
                        </li>
                        <li className="para">Channels</li>
                        <li className="para">EMA- Double</li>
                        <li className="para">Pivot Points</li>
                        <li className="para">Trading Divergences- Hidden</li>
                        <li className="para">Price Gap</li>
                        <li className="para">Candle Setup Theory</li>
                        <li className="para">Candlestick Pattern</li>
                        <li className="para">Trade Volume</li>
                        <li className="para">
                          Open Interest and Decision Making
                        </li>
                        <li className="para">Fibonacci</li>
                        <li className="para">Live Trading</li>
                      </ol>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingFour">
                    <p
                      class="accordion-button collapsed accordionheading"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseFour"
                      aria-expanded="false"
                      aria-controls="flush-collapseFour"
                    >
                      Advance Technical Analysis
                    </p>
                  </h2>
                  <div
                    id="flush-collapseFour"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingFour"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body">
                      <ol>
                        <li className="para">Time the Market</li>
                        <li className="para">Insider Data</li>
                        <li className="para">Risk Management</li>
                        <li className="para">Money Management</li>
                        <li className="para">Explosion Trade</li>
                      </ol>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingFive">
                    <p
                      class="accordion-button collapsed accordionheading"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseFive"
                      aria-expanded="false"
                      aria-controls="flush-collapseFive"
                    >
                      Psychology and Risk Management
                    </p>
                  </h2>
                  <div
                    id="flush-collapseFive"
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingFive"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body">
                      <ol>
                        <li className="para">
                          Identification (Source and Problem Analysis)
                        </li>
                        <li className="para">Planning</li>
                        <li className="para">Constraints</li>
                        <li className="para">Framework</li>
                        <li className="para">Developing an Analysis</li>
                        <li className="para">Mitigation</li>
                        <li className="para">Other Risk Treatment</li>
                        <div>
                          <p className="heading mt-5">
                            Who can take this course?
                          </p>
                          <p className="para">
                            It is designed to help Students, Day traders,
                            Investors, Sub-brokers, stock Brokers, Chartered
                            accountants, Housewives Relationship  Managers,
                            Traders, Consultants, Entrepreneurs, etc.
                          </p>
                          <p className="para">Features & Benefits</p>
                          <ol>
                            <li className="para">Instalment Facility</li>
                            <li className="para">
                              Daily Live trading in the classroom
                            </li>
                            <li className="para">3+ Mentors Cum Traders</li>
                            <li className="para">
                              Retakes and Class Backups Available
                            </li>
                            <li className="para">Lifetime Support</li>
                            <li className="para">
                              Free Demat A/c Opening with Upstox
                            </li>
                          </ol>
                        </div>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <img
                src={trading1}
                width={"100%"}
                alt="trading smart edge images"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfessionalTraderCourse;
